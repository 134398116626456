@import '~normalize.css';

.dynamic-form {
  .slider__extreme_value_right {
    padding-right: 0px;
    float: right;
  }
  .slider__extreme_value_left {
    padding-left: 0px;
    float: left;
  }
}

body {
  overflow: hidden;
}

#root {
  height: 100vh;
  position: relative;
  width: 100vw;
}

@primary-color: #4888d0;@primary-color-light: #40a9ff;