.dynamic-form {
  .slider__extreme_value_right {
    padding-right: 0px !important;
    float: right;
  }
  .slider__extreme_value_left {
    padding-left: 0px !important;
    float: left;
  }
}
